import consumer from "./consumer"

consumer.subscriptions.create("CommentChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if(data) {
      let tableRef = document.getElementById("comments");
      let newRow = tableRef.insertRow(0);
      let newCell = newRow.insertCell(0);
      let h4Tag = document.createElement('h4');
      let uTag = document.createElement('u');
      let userInitials = document.createTextNode(data.object.user + ': ');
      uTag.appendChild(userInitials);
      h4Tag.appendChild(uTag);

      let bTag = document.createElement('b');
      bTag.setAttribute("style", "color: black");
      let message = document.createTextNode(data.object.message);

      bTag.appendChild(message);
      h4Tag.appendChild(bTag);
      newCell.appendChild(h4Tag);
    }
  }
});